.sui-search-box__submit{
    background: #474787 !important;
}

.sui-search-box{
    max-width: 1000px;
    margin: auto;
}

em{
    background-color: rgba(71, 71, 135,.2);
}

.sui-layout-sidebar{
    width : 28% !important;
}